// @flow
export const k12States = [
  { value: 1, label: 'Alabama' },
  { value: 2, label: 'Alaska' },
  { value: 3, label: 'Arizona' },
  { value: 4, label: 'Arkansas' },
  { value: 5, label: 'California' },
  { value: 6, label: 'Colorado' },
  { value: 7, label: 'Connecticut' },
  { value: 8, label: 'Delaware' },
  { value: 9, label: 'Florida' },
  { value: 10, label: 'Georgia' },
  { value: 11, label: 'Hawaii' },
  { value: 12, label: 'Idaho' },
  { value: 13, label: 'Illinois' },
  { value: 14, label: 'Indiana' },
  { value: 15, label: 'Iowa' },
  { value: 16, label: 'Kansas' },
  { value: 17, label: 'Kentucky' },
  { value: 18, label: 'Louisiana' },
  { value: 19, label: 'Maine' },
  { value: 20, label: 'Maryland' },
  { value: 21, label: 'Massachusetts' },
  { value: 22, label: 'Michigan' },
  { value: 23, label: 'Minnesota' },
  { value: 24, label: 'Mississippi' },
  { value: 25, label: 'Missouri' },
  { value: 26, label: 'Montana' },
  { value: 27, label: 'Nebraska' },
  { value: 28, label: 'Nevada' },
  { value: 29, label: 'New Hampshire' },
  { value: 30, label: 'New Jersey' },
  { value: 31, label: 'New Mexico' },
  { value: 32, label: 'New York' },
  { value: 33, label: 'North Carolina' },
  { value: 34, label: 'North Dakota' },
  { value: 35, label: 'Ohio' },
  { value: 36, label: 'Oklahoma' },
  { value: 37, label: 'Oregon' },
  { value: 38, label: 'Pennsylvania' },
  { value: 39, label: 'Rhode Island' },
  { value: 40, label: 'South Carolina' },
  { value: 41, label: 'South Dakota' },
  { value: 42, label: 'Tennessee' },
  { value: 43, label: 'Texas' },
  { value: 44, label: 'Utah' },
  { value: 45, label: 'Vermont' },
  { value: 46, label: 'Virginia' },
  { value: 47, label: 'Washington' },
  { value: 48, label: 'West Virginia' },
  { value: 49, label: 'Wisconsin' },
  { value: 50, label: 'Wyoming' },
  { value: 51, label: 'Washington D.C.' }
];

export const k12Grades = [
  { value: 1, label: '1st Grade' },
  { value: 2, label: '2nd Grade' },
  { value: 3, label: '3rd Grade' },
  { value: 4, label: '4th Grade' },
  { value: 5, label: '5th Grade' },
  { value: 6, label: '6th Grade' },
  { value: 7, label: '7th Grade' },
  { value: 8, label: '8th Grade' },
  { value: 9, label: '9th Grade' },
  { value: 10, label: '10th Grade' },
  { value: 11, label: '11th Grade' },
  { value: 12, label: '12th Grade' }
];

export const collegeStates = [{ value: 28, label: 'Nevada' }];

export const collegeGrades = [
  { value: 1, label: 'Freshman' },
  { value: 2, label: 'Sophomore' },
  { value: 3, label: 'Junior' },
  { value: 4, label: 'Senior' }
];

export const colleges = [
  { value: 1, label: 'Western Nevada College' },
  { value: 2, label: 'Nevada State College' }
];

export const states = {
  'K-12': k12States,
  College: collegeStates
};

export const grades = {
  'K-12': k12Grades,
  College: collegeGrades
};
