// @flow

import React, { Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DialogTitle from '../DialogTitle';

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 2
  },
  link: {
    padding: theme.spacing.unit,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.circleIn.palette.primaryText1,
    borderRadius: 4,
    marginRight: theme.spacing.unit * 2
  }
});

type Props = {
  classes: Object,
  open: boolean,
  isLoading: boolean,
  link: string,
  onClose: Function,
  onLinkCopied: Function
};

type State = {};

class ShareDialog extends React.PureComponent<Props, State> {
  render() {
    const {
      classes,
      open,
      link,
      isLoading,
      onLinkCopied,
      onClose
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="share-dialog-title"
        aria-describedby="share-dialog-description"
      >
        <DialogTitle variant="h5" id="share-dialog-title" onClose={onClose}>
          Share this Post
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="h6"
            id="share-dialog-description"
            color="textPrimary"
          >
            Copy this link and send it to your classmates.
          </DialogContentText>
          <div className={classes.content}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Fragment>
                <div className={classes.link}>
                  <Typography variant="subtitle1">{link}</Typography>
                </div>
                <CopyToClipboard text={link} onCopy={onLinkCopied}>
                  <Button variant="contained" color="primary" autoFocus>
                    <FileCopyIcon className={classes.icon} />
                    Copy
                  </Button>
                </CopyToClipboard>
              </Fragment>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ShareDialog);
